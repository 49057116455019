
import { defineComponent } from 'vue'
import { SessionController, CompanyController, ProjectController, UserController } from '@/controller'
import { Company, CompanySettings, User } from '@/model'
import { Card, SelectSingleSearch, Button } from '@/ui' 
import store from '@/store/index'

export default defineComponent({
    name: "CompanySettings",
    components: { Card, SelectSingleSearch, Button },
    data(){
        return {
            store,
            form: {
                defaultProject: 0,
                workspaceUser: 0
            },
            projects: [] as Array<any>,
            users: [] as Array<User>,
            sessionCompany: {} as Company
        }
    },
    computed: {
        defaultProjectTitle(): string {
            const selected = this.projects.find(p => p.uid == this.form.defaultProject)
            if (selected) {
                return selected.title
            } else {
                return ''
            }
        },
        workspaceUsername(): string {
            const selected = (this.users ? this.users.find((u: User) => u.uid == this.form.workspaceUser) : null)
            if (selected) {
                return selected.username
            } else {
                return ''
            }
        } 

    },
    created() {
        this.sessionCompany = SessionController.sessionCompany
        this.form.defaultProject = (this.sessionCompany?.settings?.defaultProject as number)
        this.form.workspaceUser = (this.sessionCompany?.settings?.workspaceUser as number)
        this.getProjects()
        this.getUsers()
    },
    methods:{
        async getProjects() {
            this.projects = (await ProjectController.fetchProjects()).items;
        },        
        async getUsers() {
            const res = await UserController.getUsers() as any
            if (!res?.error) {
                this.users = res
            }
        },        
        async saveSettings() {
            const res = await CompanyController.postSettings(this.form, (this.sessionCompany?.settings?.uid as number));
            if (res.companySettings) {
                this.form.defaultProject = res.companySettings.defaultProject
                this.form.workspaceUser = res.companySettings.workspaceUser
                if (this.sessionCompany.settings) this.sessionCompany.settings = res.companySettings
                else if (this.sessionCompany.settings) (this.sessionCompany.settings as CompanySettings).setDefaultProject(res.companySettings)
                this.$notify(this.$t("success.companySettingsSaved"), { position: "top", type: "success"});
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },

            //handlers
        onSetDefaultProject(item: any, value: number, text: string) {
            this.form.defaultProject = value
        },
        onSetWorkspaceUser(item: any, value: number, text: string) {
            this.form.workspaceUser = value
        },
        onSubmitSettingsPrevent() {
            this.saveSettings();
        },
        onSaveProject() {
            this.saveSettings()
        },

            //functions
    }
})
